import * as bootstrap from "bootstrap";
import "../scss/styles.scss";

// Theme settings
const setTheme = () => {
  const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  document.documentElement.setAttribute(
    "data-theme",
    isDark ? "dark" : "light"
  );
};

setTheme();

window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", setTheme);

// Helper function to toggle active classes
const toggleActiveClasses = (element, isActive) => {
  const link = element.querySelector(".nav-link");
  const icon = element.querySelector(".navbar__icon");

  if (isActive) {
    element.classList.add("active");
    link.classList.add("active");
    icon.classList.add("active");
  } else {
    element.classList.remove("active");
    link.classList.remove("active");
    icon.classList.remove("active");
  }
};

// Navbar settings
document.addEventListener("DOMContentLoaded", () => {
  const navItems = document.querySelectorAll(".nav-item");
  const sections = document.querySelectorAll("section");

  const updateActiveLink = () => {
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      const sectionId = section.getAttribute("id");

      if (
        window.scrollY >= sectionTop - sectionHeight / 3 &&
        window.scrollY < sectionTop + sectionHeight
      ) {
        navItems.forEach((navItem) => {
          toggleActiveClasses(
            navItem,
            navItem.querySelector(".nav-link").getAttribute("href") ===
              `#${sectionId}`
          );
        });
      }
    });
  };

  const handleNavItemClick = (event) => {
    navItems.forEach((item) => toggleActiveClasses(item, false));
    toggleActiveClasses(event.currentTarget, true);
  };

  document.addEventListener("scroll", updateActiveLink);
  navItems.forEach((navItem) =>
    navItem.addEventListener("click", handleNavItemClick)
  );
});

// Hamburger menu settings
document.addEventListener("DOMContentLoaded", () => {
  const toggleMenu = () => {
    const navbarCollapse = document.getElementById("navbarNav");
    const navbarIcon = document.getElementById("navbarIcon");

    navbarCollapse.classList.toggle("show");
    navbarIcon.classList.toggle("fa-bars");
    navbarIcon.classList.toggle("fa-xmark");
  };

  window.toggleMenu = toggleMenu;
});

// Navigation bar
document.addEventListener("DOMContentLoaded", () => {
  const links = document.querySelectorAll(".nav-link");
  const navbarCollapse = document.getElementById("navbarNav");
  const navbarIcon = document.getElementById("navbarIcon");

  links.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();

      const targetId = link.getAttribute("href");
      const targetSection = document.querySelector(targetId);

      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth", block: "start" });

        if (navbarCollapse.classList.contains("show")) {
          navbarCollapse.classList.remove("show");
          navbarIcon.classList.remove("fa-xmark");
          navbarIcon.classList.add("fa-bars");
        }
      } else {
        console.error(`Element o ID ${targetId} nie został znaleziony.`);
      }
    });
  });
});
